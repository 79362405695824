import React, { useEffect } from "react";
import { navigate } from "gatsby";

const Page404: React.FC = () => {
  useEffect(() => {
    navigate("/product/2022-campaign/");
  }, []);

  return <div />;
};

export default Page404;
